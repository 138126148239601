import {IconChecklist, IconCircleKey, IconClockHour8, IconDashboard, IconList} from '@tabler/icons';

const icons = {IconDashboard, IconChecklist, IconCircleKey, IconClockHour8, IconList};

const dashboard = {
    id: 'dashboard',
    title: '',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard/default',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'stocks',
            title: 'Stocks to trade',
            type: 'item',
            url: '/stocks',
            icon: icons.IconChecklist,
            breadcrumbs: false
        },
        {
            id: 'holdings',
            title: 'Holdings & Positions',
            type: 'item',
            url: '/holdings',
            icon: icons.IconCircleKey,
            breadcrumbs: false
        },
        {
            id: 'Orders',
            title: 'Orders',
            type: 'item',
            url: '/orders',
            icon: icons.IconClockHour8,
            breadcrumbs: false
        },
        {
            id: 'Logs',
            title: 'Logs',
            type: 'item',
            url: '/logs',
            icon: icons.IconList,
            breadcrumbs: false
        }
    ]
};

export default dashboard;
