import { lazy, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthWrapper from './AuthWrapper'; // Import the AuthWrapper.js

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// Stocks Page Router
const StocksList = Loadable(lazy(() => import('views/stocks')));

// Holdings Page Router
const HoldingsList = Loadable(lazy(() => import('views/holdings')));

// Orders Page Router
const OrdersList = Loadable(lazy(() => import('views/orders')));

// Logs Page Router
const LogsList = Loadable(lazy(() => import('views/logs')));

const MainRoutes = {
  path: '/',
  element: <AuthWrapper><MainLayout /></AuthWrapper>,
  children: [
    {
      path: '/',
      element: <DashboardDefault />
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'default',
          element: <DashboardDefault />
        }
      ]
    },
    {
      path: 'utils',
      children: [
        {
          path: 'util-typography',
          element: <UtilsTypography />
        },
        {
          path: 'util-color',
          element: <UtilsColor />
        },
        {
          path: 'util-shadow',
          element: <UtilsShadow />
        }
      ]
    },
    {
      path: 'icons',
      children: [
        {
          path: 'tabler-icons',
          element: <UtilsTablerIcons />
        },
        {
          path: 'material-icons',
          element: <UtilsMaterialIcons />
        }
      ]
    },
    {
      path: 'sample-page',
      element: <SamplePage />
    },
    {
      path: 'stocks',
      element: <StocksList />
    },
    {
      path: 'holdings',
      element: <HoldingsList />
    },
    {
      path: 'orders',
      element: <OrdersList />
    },
    {
      path: 'logs',
      element: <LogsList />
    }
  ]
};

export default MainRoutes;
