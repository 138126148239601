import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
export const API_URL = process.env.REACT_APP_API_URL

// Load token logic
const loadToken = () => {
    return localStorage.getItem('authToken');
};

// Authentication check function
const useAuth = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true); // Add a loading state

    useEffect(() => {
        const token = loadToken();
        setIsLoading(true); // Set loading to true when starting the check

        if (token) {
            axios.post(API_URL + '/api/auth/verify', JSON.stringify({token}), {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    if (response.data.valid) {
                        setIsAuthenticated(true);
                    } else {
                        setIsAuthenticated(false);
                        localStorage.removeItem('authToken');
                    }
                })
                .catch(() => {
                    setIsAuthenticated(false);
                    localStorage.removeItem('authToken');
                })
                .finally(() => setIsLoading(false)); // Set loading to false when check is complete
        } else {
            setIsLoading(false); // Ensure loading is set to false if no token
        }
    }, []);

    return { isAuthenticated, isLoading }; // Return both isAuthenticated and isLoading
};


// Authentication wrapper component
const AuthWrapper = ({ children }) => {
    const navigate = useNavigate();
    const { isAuthenticated, isLoading } = useAuth(); // Destructure to get isLoading

    useEffect(() => {
        if (!isLoading) { // Only navigate when not loading
            if (!isAuthenticated) {
                navigate('/login');
            }
        }
    }, [isAuthenticated, isLoading, navigate]); // Add isLoading to the dependency array

    if (isLoading) return <div>Loading...</div>; // Show loading indicator or return null while checking

    return isAuthenticated ? children : null;
};

export default AuthWrapper;
